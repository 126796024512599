<template>
  <div class="form-group">
    <label>마켓운영시간</label>
    <div class="bizHour">
      <div class="openTime">
        <b-form-timepicker
          id="input-stime"
          placeholder="여는시간"
          @input="set_stime"
          :class="{ 'is-invalid': set_stime_validation() }"
          locale="ko"
        />
      </div>
      ~
      <div class="closeTime">
        <b-form-timepicker
          id="input-etime"
          placeholder="닫는시간"
          @input="set_etime"
          :class="{ 'is-invalid': set_etime_validation() }"
          locale="ko"
        />
      </div>
    </div>
    <div
      v-if="set_stime_validation() || set_etime_validation()"
      class="invalid-feedback"
    >
      {{ d_InvalidText }}
    </div>
  </div>
</template>

<script>
import { signUpComputed, signUpMethods } from "@/state/modules/signUpHelper";
export default {
  name: "MartTimeForm",
  data: () => ({
    d_IsChanged: false,
    d_etimeIsChanged: false,
    d_InvalidText: ""
  }),

  computed: { ...signUpComputed },
  methods: {
    ...signUpMethods,
    stime_validator(val) {
      this.setStimeValidAct({ payload: val });
    },
    etime_validator(val) {
      this.setEtimeValidAct({ payload: val });
    },
    chk_times(stime, etime) {
      const timeComparison =
        parseInt(stime?.replace(":", "")) - parseInt(etime?.replace(":", ""));
      return timeComparison < 0;
    },
    set_stime_validation() {
      return (
        (this.signUpValid.submit || this.d_IsChanged) && !this.signUpValid.stime
      );
    },
    set_etime_validation() {
      return (
        (this.signUpValid.submit || this.d_IsChanged) && !this.signUpValid.etime
      );
    },
    set_stime(e) {
      this.d_IsChanged = true;
      if (e === "") {
        this.stime_validator(false);
        this.d_InvalidText = "여는시간을 입력해주세요.";
      } else if (!this.chk_times(e, this.signUpObj.etime)) {
        this.stime_validator(false);
        this.etime_validator(false);
        this.d_InvalidText =
          "닫는시간이 여는시간보다 이전 시간 입니다. 수정해주세요.";
      } else {
        this.stime_validator(true);
        this.etime_validator(true);
        this.d_InvalidText = "";
      }
      this.setStimeAct({ payload: e });
      console.log("store", this.signUpObj.stime);
    },
    set_etime(e) {
      this.d_IsChanged = true;
      if (e === "") {
        this.etime_validator(false);
        this.d_InvalidText = "닫는시간을 입력해주세요.";
      } else if (!this.chk_times(this.signUpObj.stime, e)) {
        this.stime_validator(false);
        this.etime_validator(false);
        this.d_InvalidText =
          "닫는시간이 여는시간보다 이전 시간 입니다. 수정해주세요.";
      } else {
        this.stime_validator(true);
        this.etime_validator(true);
        this.d_InvalidText = "";
      }
      this.setEtimeAct({ payload: e });
    }
  }
};
</script>
