<template>
  <div>
    <!--    비밀번호-->
    <div class="form-group">
      <label>비밀번호</label>
      <input
        type="password"
        class="form-control"
        id="input-pwd"
        placeholder="비밀번호"
        maxlength="13"
        :value="signUpObj.pw"
        @input="set_pwd"
        :class="{
          'is-invalid': set_validation()
        }"
      />
      <div v-if="set_validation()" class="invalid-feedback">
        {{ d_pwInvalidText }}
      </div>
    </div>
    <!--비밀번호확인-->
    <div class="form-group">
      <label>비밀번호확인</label>
      <input
        type="password"
        class="form-control"
        id="input-pwdchk"
        placeholder="비밀번호확인"
        @input="set_pwdChk"
        :class="{ 'is-invalid': d_pwChkInvalid }"
      />
      <div v-if="d_pwChkInvalid" class="invalid-feedback">
        {{ d_pwChkInvalidText }}
      </div>
    </div>
  </div>
</template>

<script>
import { signUpComputed, signUpMethods } from "@/state/modules/signUpHelper";

export default {
  name: "PwdForm",
  data: () => ({
    d_isKeyPressed: false,
    d_pwInvalidText: "",
    d_pwChkInvalid: false,
    d_pwChkInvalidText: ""
  }),
  computed: { ...signUpComputed },
  methods: {
    ...signUpMethods,
    validator(val) {
      this.setPwValidAct({ payload: val });
    },
    set_validation() {
      return (
        (this.signUpValid.submit || this.d_isKeyPressed) && !this.signUpValid.pw
      );
    },
    chk_pw_valid(valid, msg) {
      this.validator(valid);
      this.d_pwInvalidText = msg;
    },
    chk_pwChk_Invalid(invalid, msg) {
      this.d_pwChkInvalid = invalid;
      this.d_pwChkInvalidText = msg;
    },
    set_pwd(e) {
      this.d_isKeyPressed = true;
      this.set_pwdChk(e);
      if (e.target.value === "") {
        this.chk_pw_valid(false, "비밀번호를 입력해주세요.");
      } else if (e.target.value.length < 8) {
        this.chk_pw_valid(false, "비밀번호는 8~13자 까지 입력해주세요.");
      } else {
        this.chk_pw_valid(true);
      }
      this.setPwAct({ payload: e.target.value });
    },

    set_pwdChk(e) {
      if (e.target.value === "") {
        this.chk_pwChk_Invalid(true, "비밀번호 확인을 입력해주세요.");
      } else if (e.target.value !== this.signUpObj.pw) {
        this.chk_pwChk_Invalid(true, "입력한 비밀번호와 일치하지 않습니다.");
      } else {
        this.chk_pwChk_Invalid(false);
      }
    }
  }
};
</script>
