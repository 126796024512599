<template>
  <div class="btnFormWrap form-group">
    <label>마켓주소</label>
    <div class="btnFormItem addr" @click="find_addr">
      <input
        type="text"
        class="form-control"
        id="input-addr"
        disabled
        placeholder="주소 찾기를 해주세요"
        :value="signUpObj.addr"
        :class="{ 'is-invalid': set_addr_validation() }"
      />
      <button type="button" class="btn btn-outline-primary">
        주소찾기
      </button>
    </div>
    <input
      type="text"
      class="form-control"
      id="input-addrDetail"
      placeholder="상세주소"
      :value="signUpObj.addr_detail"
      @input="set_addr_detail"
      :class="{ 'is-invalid': set_addrDetail_validation() }"
    />
    <div v-if="set_addr_validation()" class="invalid-feedback">
      주소찾기를 해주세요.
    </div>
    <div v-if="set_addrDetail_validation()" class="invalid-feedback">
      상세주소를 입력해주세요.
    </div>
    <b-modal id="modalAddr">
      <div ref="searchAddress" style="width: 100%; height: 500px"></div>
    </b-modal>
  </div>
</template>

<script>
import { signUpComputed, signUpMethods } from "@/state/modules/signUpHelper";
import { get_geoApi } from "@/api/signUpApi";

export default {
  name: "AddrForm",
  data: () => ({
    d_addr_IsChanged: false,
    d_addrDetail_IsChanged: false,
    addrInvalid: false,
    addrInvalidText: ""
  }),
  computed: { ...signUpComputed },
  mounted() {
    let daumApiSc = document.createElement("script");
    daumApiSc.setAttribute(
      "src",
      "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"
    );
    document.head.appendChild(daumApiSc);
  },
  methods: {
    ...signUpMethods,
    addr_validator(val) {
      this.setAddrValidAct({ payload: val });
    },
    addrDetail_validator(val) {
      this.setAddrDetailValidAct({ payload: val });
    },
    set_addr_validation() {
      return (
        (this.signUpValid.submit || this.d_addr_IsChanged) &&
        !this.signUpValid.addr
      );
    },
    set_addrDetail_validation() {
      return (
        (this.signUpValid.submit || this.d_addrDetail_IsChanged) &&
        !this.signUpValid.addr_detail
      );
    },
    async find_addr(e) {
      e.preventDefault();
      await this.$bvModal.show("modalAddr"); //모달 로드가 완료 된 후에 주소 api를 실행
      this.d_addr_IsChanged = true;
      this.showApi();
    },
    showApi() {
      new window.daum.Postcode({
        oncomplete: async data => {
          // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

          // 도로명 주소의 노출 규칙에 따라 주소를 조합한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          let fullRoadAddr = data.roadAddress; // 도로명 주소 변수
          let extraRoadAddr = ""; // 도로명 조합형 주소 변수

          // 법정동명이 있을 경우 추가한다. (법정리는 제외)
          // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
          if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
            extraRoadAddr += data.bname;
          }
          // 건물명이 있고, 공동주택일 경우 추가한다.
          if (data.buildingName !== "" && data.apartment === "Y") {
            extraRoadAddr +=
              extraRoadAddr !== ""
                ? ", " + data.buildingName
                : data.buildingName;
          }
          // 도로명, 지번 조합형 주소가 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
          if (extraRoadAddr !== "") {
            extraRoadAddr = " (" + extraRoadAddr + ")";
          }
          // 도로명, 지번 주소의 유무에 따라 해당 조합형 주소를 추가한다.
          if (fullRoadAddr !== "") {
            fullRoadAddr += extraRoadAddr;
          }
          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          this.setAddrAct({ payload: fullRoadAddr });
          const geo = await get_geoApi(fullRoadAddr);
          // 위도 경도 받아오기
          this.setWidoAct({ payload: geo.wido });
          this.setGyeongdoAct({ payload: geo.gyeongdo });
          this.addr_validator(true);
          // 모달 닫기
          this.$bvModal.hide("modalAddr");
        },
        width: "100%",
        height: "100%"
      }).embed(this.$refs.searchAddress);
    },
    set_addr_detail(e) {
      this.d_addrDetail_IsChanged = true;
      if (e.target.value === "") {
        this.addrDetail_validator(false);
      } else {
        this.addrDetail_validator(true);
      }
      this.setAddrDetailAct({ payload: e.target.value });
    }
  }
};
</script>
