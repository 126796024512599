<template>
  <SignUpLayout>
    <MobileHeader :title="'회원가입'" :router="pageBack" />
    <div class="signup" v-if="this.signUpObj.business_type">
      <form>
        <BizTypeForm />
        <BizNumForm />
        <PwdForm />
        <MartNameForm />
        <TelForm />
        <MartTimeForm />
        <IdentifyForm />
        <AddrForm />
      </form>
      <b-btn @click="submit">회원가입</b-btn>
    </div>
  </SignUpLayout>
</template>

<script>
import "./signup.scss";
import SignUpLayout from "@/views/pages/account/signup/layout/SignUpLayout.vue";
import MobileHeader from "@/views/pages/account/signup/module/MobileHeader.vue";
import { signUpComputed, signUpMethods } from "@/state/modules/signUpHelper";
import BizNumForm from "@/views/pages/account/signup/atom/BizNumForm.vue";
import PwdForm from "@/views/pages/account/signup/atom/PwdForm.vue";
import MartNameForm from "@/views/pages/account/signup/atom/MartNameForm.vue";
import TelForm from "@/views/pages/account/signup/atom/TelForm.vue";
import MartTimeForm from "@/views/pages/account/signup/atom/MartTimeForm.vue";
import IdentifyForm from "@/views/pages/account/signup/atom/IdentifyForm.vue";
import AddrForm from "@/views/pages/account/signup/atom/AddrForm.vue";
import BizTypeForm from "@/views/pages/account/signup/atom/BizTypeForm.vue";
import { post_userApi } from "@/api/signUpApi";

export default {
  name: "signup",
  components: {
    BizTypeForm,
    AddrForm,
    IdentifyForm,
    MartTimeForm,
    TelForm,
    MartNameForm,
    PwdForm,
    BizNumForm,
    MobileHeader,
    SignUpLayout
  },
  data: () => {
    return {
      showSignupPage: false,
      valid: false,
      submitted: false
    };
  },
  computed: { ...signUpComputed },
  created() {
    if (this.signUpObj.business_type === "") {
      this.pageBack();
    }
  },
  methods: {
    ...signUpMethods,
    PageChange() {
      this.showSignupPage = true;
    },
    pageBack() {
      this.$router.push("/signup_biz");
    },
    async submit() {
      this.setSubmitAct({ payload: true });
      // console.log(this.signUpObj);
      // console.log(this.signUpValid);
      this.valid = Object.keys(this.signUpValid).find(
        key => this.signUpValid[key] === false
      );
      if (this.valid) return;
      // console.log(this.encrypt(this.signUpObj));
      const postUser = post_userApi(this.encrypt(this.signUpObj));
      if (!postUser) {
        alert("회원가입에 실패했습니다. 잠시 후 다시 시도해주세요.");
      } else {
        this.resetAllAct();
        alert("회원가입 성공! 로그인 해주세요.");
        this.$router.push("/login");
      }
    },
    encrypt(formData) {
      const key = this.$CryptoJS.enc.Utf8.parse(process.env.VUE_APP_KEY);
      const iv = this.$CryptoJS.enc.Hex.parse(process.env.VUE_APP_IV);
      let encrypt = this.$CryptoJS.AES.encrypt(JSON.stringify(formData), key, {
        iv: iv
      });
      return encrypt.toString().replaceAll("/", "*");
    }
  }
};
</script>
