<template>
  <!--        마켓명-->
  <div class="form-group">
    <label>마켓명</label>
    <input
      type="text"
      class="form-control"
      id="input-martname"
      placeholder="상호명과 일치하게 입력하세요"
      :value="signUpObj.martname"
      @input="set_martname"
      :class="{ 'is-invalid': set_vaildation() }"
    />
    <div v-if="set_vaildation()" class="invalid-feedback">
      {{ d_invalidText }}
    </div>
  </div>
</template>

<script>
import { signUpComputed, signUpMethods } from "@/state/modules/signUpHelper";
export default {
  name: "MartNameForm",
  data: () => ({
    d_isKeyPressed: false,
    d_invalidText: ""
  }),

  computed: { ...signUpComputed },
  methods: {
    ...signUpMethods,
    validator(val) {
      this.setMartNameValidAct({ payload: val });
    },
    set_vaildation() {
      return (
        (this.signUpValid.submit || this.d_isKeyPressed) &&
        !this.signUpValid.martname
      );
    },
    set_martname(e) {
      this.d_isKeyPressed = true;
      if (e.target.value === "") {
        this.validator(false);
        this.d_invalidText = "상호명을 입력하세요.";
      } else {
        this.validator(true);
        this.d_invalidText = "";
      }
      this.setMartNameAct({ payload: e.target.value });
    }
  }
};
</script>
