<template>
  <!--업종선택-->
  <div class="SignUpLayout">
    <slot />
  </div>
</template>

<script>
export default {
  name: "SignUpLayout",
  encrypt() {
    // eslint-disable-next-line
    // const key = this.$CryptoJS.enc.Utf8.parse(process.env.VUE_APP_KEY);
    // eslint-disable-next-line
    // const iv = this.$CryptoJS.enc.Hex.parse(process.env.VUE_APP_IV);
    // let encrypt = this.$CryptoJS.AES.encrypt(JSON.stringify(accountInfo), key, {iv: iv});
    // let strEncrypt = encrypt.toString().replaceAll('/', '*');
  }
};
</script>

<style lang="scss">
.SignUpLayout {
  background: #ffffff;
}
</style>
