<template>
  <div class="MobileHeader">
    <button class="btn primary-outline" @click="router">
      <img :src="require(`@/assets/images/account/ico_left_carret.png`)"  alt=""/>
    </button>
    <h1>{{ title }}</h1>
  </div>
</template>

<script>
export default {
  name: "MobileHeader",
  props: {
    title: String,
    router: Function
  }
};
</script>

<style lang="scss">
.MobileHeader {
  position: relative;
  width: 100%;
  height: 56px;
  border-bottom: 1px solid #bdbdbd;
  > button {
    width: 60px;
    > img {
      width: 100%;
    }
  }
  > h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 18px;
    color: #222;
  }
}
</style>
