<template>
  <!--        아이디-->
  <div class="btnFormWrap form-group">
    <label for="input-id">아이디</label>
    <div class="btnFormItem">
      <input
        type="text"
        class="form-control"
        id="input-id"
        placeholder="사업자번호 10자리"
        maxlength="10"
        :value="signUpObj.biznum"
        @input="set_biznum"
        :class="{
          'is-invalid': set_vaildation(),
          'is-valid': d_bizNumChk
        }"
      />
      <button type="button" class="btn btn-outline-primary" @click="chk_biznum">
        중복확인
      </button>
    </div>
    <div v-if="d_bizNumChk" class="valid-feedback">
      {{ d_validText }}
    </div>
    <div v-if="set_vaildation()" class="invalid-feedback">
      {{ d_invalidText }}
    </div>
  </div>
</template>

<script>
import { signUpComputed, signUpMethods } from "@/state/modules/signUpHelper";
import { chk_biznumApi } from "@/api/signUpApi";
import { regexNum } from "@/views/pages/account/signup/atom/regex";

export default {
  name: "BizNumForm",
  data: () => ({
    d_IsChanged: false,
    d_isVaild: false,
    d_invalidText: "",
    d_validText: "",
    d_bizNumChk: false
  }),

  computed: { ...signUpComputed },
  methods: {
    ...signUpMethods,
    validator(val) {
      this.setBizNumValidAct({ payload: val });
    },
    set_vaildation() {
      return (
        (this.signUpValid.submit || this.d_IsChanged) &&
        !this.signUpValid.biznum
      );
    },
    chk_valid(valid, msg) {
      this.validator(valid);
      this.d_bizNumChk = valid;
      if (valid) this.d_validText = msg;
      else this.d_invalidText = msg;
    },
    set_biznum(e) {
      this.d_IsChanged = true;
      e.target.value = e.target.value.replace(regexNum, "");
      if (e.target.value === "") {
        this.chk_valid(false, "사업자 번호를 입력해주세요.");
      } else if (e.target.value.length < 10) {
        this.chk_valid(false, "사업자 번호 10자리를 입력해주세요.");
      } else {
        this.chk_valid(false, "중복확인이 필요합니다.");
      }
      this.setBizNumAct({ payload: e.target.value });
    },
    async chk_biznum() {
      this.d_IsChanged = true;
      if (this.signUpObj.biznum.length < 10) {
        this.chk_valid(false, "사업자 번호 10자리를 입력해주세요.");
        return;
      }
      let res = await chk_biznumApi(this.signUpObj.biznum);
      if (res.status === "already exists") {
        this.chk_valid(false, "이미 가입된 사업자 번호 입니다");
        return;
      }
      if (res.status === "success") {
        this.chk_valid(
          true,
          "사용가능한 사업자 번호입니다! 회원가입을 계속 진행해주세요"
        );
        return;
      }
    }
  }
};
</script>
