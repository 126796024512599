<template>
  <!--        아이디-->
  <div class="btnFormWrap form-group">
    <label for="input-id">업종유형</label>

    <div class="btnFormItem">
      <input
        type="text"
        class="form-control"
        id="input-id"
        disabled
        :value="set_bizType()"
      />
      <router-link :to="'/signup_biz'">
        <button class="btn btn-outline-primary">
          변경
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { signUpComputed, signUpMethods } from "@/state/modules/signUpHelper";

export default {
  name: "BizTypeForm",
  computed: { ...signUpComputed },
  methods: {
    ...signUpMethods,
    set_bizType() {
      if (this.signUpObj.business_type === "mart") return "할인마트";
      else if (this.signUpObj.business_type === "butcher") return "정육";
      else if (this.signUpObj.business_type === "veg") return "채소/과일";
      else if (this.signUpObj.business_type === "side") return "반찬";
    }
  }
};
</script>
