<template>
  <div>
    <!--    대표자명-->
    <div class="form-group">
      <label>대표자명</label>
      <input
        type="text"
        class="form-control"
        id="input-ceoname"
        placeholder="ex)홍길동"
        :disabled="signUpValid.imp_uid"
        :value="signUpObj.ceoname"
        @input="set_ceoname"
        :class="{ 'is-invalid': set_ceoname_validation() }"
      />
      <div v-if="set_ceoname_validation()" class="invalid-feedback">
        {{ d_ceonameInvalidText }}
      </div>
    </div>
    <!--    대표자휴대폰번호-->
    <div class="btnFormWrap form-group">
      <label>대표자 휴대폰번호</label>
      <div class="btnFormItem">
        <input
          type="text"
          class="form-control"
          id="input-phone"
          placeholder="'-'없이 입력해주세요"
          maxlength="11"
          :disabled="signUpValid.imp_uid"
          :value="signUpObj.phone"
          @input="set_phone"
          :class="{ 'is-invalid': set_phone_validation() }"
        />
        <button
          type="button"
          class="btn btn-outline-primary"
          @click="onCertification"
        >
          본인인증
        </button>
      </div>
      <div v-if="set_phone_validation()" class="invalid-feedback">
        {{ d_phoneInvalidText }}
      </div>
      <div v-if="signUpValid.imp_uid" class="valid-feedback">
        {{ d_impUidVaildText }}
      </div>
      <div v-if="!signUpValid.imp_uid" class="invalid-feedback">
        {{ d_impUidInvaildText }}
      </div>
    </div>
  </div>
</template>

<script>
import { signUpComputed, signUpMethods } from "@/state/modules/signUpHelper";
import { regexNum } from "@/views/pages/account/signup/atom/regex";
export default {
  name: "IdentifyForm",
  data: () => ({
    d_ceoname_IsChanged: false,
    d_phone_IsChanged: false,
    d_d_impUid_IsChanged: false,

    d_ceonameInvalidText: "",
    d_phoneInvalidText: "",

    d_impUidVaildText: "",
    d_impUidInvaildText: ""
  }),
  computed: { ...signUpComputed },
  mounted() {
    //외부 스크립트 삽입
    let jqueryScript = document.createElement("script");
    jqueryScript.setAttribute(
      "src",
      "https://code.jquery.com/jquery-1.12.4.min.js"
    );
    let importScript = document.createElement("script");
    importScript.setAttribute(
      "src",
      "https://cdn.iamport.kr/js/iamport.payment-1.1.8.js"
    );

    document.head.appendChild(jqueryScript);
    document.head.appendChild(importScript);
  },
  methods: {
    ...signUpMethods,
    ceoname_validator(val) {
      this.setCeonameValidAct({ payload: val });
    },
    phone_validator(val) {
      this.setPhoneValidAct({ payload: val });
    },
    impUid_validator(val) {
      this.setImpUidValidAct({ payload: val });
    },
    set_ceoname_validation() {
      return (
        (this.signUpValid.submit || this.d_ceoname_IsChanged) &&
        !this.signUpValid.ceoname
      );
    },
    set_phone_validation() {
      return (
        (this.signUpValid.submit || this.d_phone_IsChanged) &&
        !this.signUpValid.phone
      );
    },
    set_impUid_validation() {
      if (!this.signUpValid.ceoname || !this.signUpValid.phone) {
        this.d_ceoname_IsChanged = true;
        this.d_phone_IsChanged = true;
        this.ceoname_validator(this.signUpValid.ceoname);
        this.phone_validator(this.signUpValid.phone);
        this.d_impUidVaildText = "";
        this.d_impUidInvaildText =
          "본인인증을 위해 대표자명과 휴대폰번호를 입력해주세요!";
        return;
      } else {
        this.d_impUidVaildText = "";
        this.d_impUidInvaildText = "";
      }
    },
    set_ceoname(e) {
      this.d_ceoname_IsChanged = true;
      if (e.target.value === "") {
        this.ceoname_validator(false);
        this.d_ceonameInvalidText = "대표자명을 입력해주세요.";
      } else {
        this.ceoname_validator(true);
        this.d_impUidVaildText = "";
        this.d_impUidInvaildText = "";
      }
      this.setCeonameAct({ payload: e.target.value });
    },
    set_phone(e) {
      this.d_phone_IsChanged = true;
      e.target.value = e.target.value.replace(regexNum, "");
      if (e.target.value.length < 10) {
        this.phone_validator(false);
        this.d_phoneInvalidText = "휴대폰번호 10~11자리를 입력해주세요.";
      } else {
        this.phone_validator(true);
        this.d_phoneInvalidText = "";
        this.d_impUidInvaildText = "";
      }
      this.setPhoneAct({ payload: e.target.value });
    },
    onCertification() {
      if (!this.signUpValid.ceoname || !this.signUpValid.phone) {
        this.set_impUid_validation();
        return;
      }
      /* 1. 가맹점 식별하기 */
      const { IMP } = window;
      IMP.init("imp94397446");

      /* 2. 본인인증 데이터 정의하기 */
      const data = {
        merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
        name: this.signUpObj.ceoname, // 이름
        phone: this.signUpObj.phone
      };

      /* 4. 본인인증 창 호출하기 */
      IMP.certification(data, this.callback);
    },
    callback(response) {
      const { success, error_msg, imp_uid } = response;
      console.log(response);
      if (success) {
        this.setImpUidAct({ payload: imp_uid });
        this.impUid_validator(true);
        this.d_impUidVaildText = "본인인증 성공";
        this.d_impUidInvaildText = "";
      } else {
        this.impUid_validator(false);
        this.d_impUidVaildText = "";
        this.d_impUidInvaildText = "본인인증 실패! 다시 시도해주세요";
        console.error(error_msg);
      }
    }
  }
};
</script>
